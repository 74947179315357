//import './bootstrap';
import '../css/owl.carousel.min.css';
import '../js/owl.carousel.min.js';
import '../css/t-datepicker.min.css';
import '../css/t-datepicker-green.css';
import '../js/t-datepicker.min.js';

import.meta.glob([
    '../img/**'
]);
//'../fonts/**',

const x = function() {
    var a = "option-default " + $("body").attr("class"),
        a = a.split(" "),
        b;
    for (b in a)
        if ("undefined" !== x.exe[a[b]])
            for (var c in x.exe[a[b]]) eval(x.exe[a[b]][
                [c]
            ])
};
x.exe = {}, x.replace = function(e, n) {
    console.log(e+' '+n)
    for (var t in n) e = e.replace(new RegExp("{" + t + "}", "gi"), n[t]);
    return e
}, x.getUrlVars = function() {
    var e, n, t = {},
        a = window.location.href.slice(window.location.href.indexOf("?") + 1).split("&");
    for (n in a) e = a[n].split("="), t[e[0]] = e[1];
    return t
}, x.getMetaSettings = function(e, n) {
    e += n || ":";
    for (var t, a, r = $("meta[name^='" + e + "']"), o = r.length, i = new RegExp(e), c = {}, x = 0; o > x; x++) t = r.eq(x), a = t.attr("name"), a = a.replace(i, ""), t = t.attr("content"), c[a] = t;
    return c
}, $(x);

function scrollToo ($e, duration, y) {
    y = y || 0;
    duration = duration || 300;
    var _top = $e.offset().top + y;
    $('html,body').animate({'scrollTop' : _top}, duration);
    return false;
}

function placeholder () {
    var fields = $('.forma__field .forma__field__itext, .forma__field textarea');
    var selectors = $('.forma__field .forma__field__select');

    fields.each(function() {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
    });

    selectors.each(function() {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
    });

    fields.on('keyup', function () {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
        else $(this).parent().find('label').show();
    }).on('focusin', function () {
        $(this).parent().addClass('forma__inp_light');
    }).on('focusout', function () {
        $(this).parent().removeClass('forma__inp_light');
    });

    selectors.on('change', function() {
        $(this).val() != '' ? $(this).parent().find('label').hide() : $(this).parent().find('label').show();
    });
}

/*********************************
********* + Menu mobile **********
*********************************/

function menuToggle () {
    var body = $('body');
    var menu = $('#mm');
    var toggleBut = $('.menuToggle');
    var plusBut = $('#mm .mm__parent');

    toggleBut.on('click', function () {
        if (menu.css('display') == 'block') {
            //menu.animate({'opacity': 0}, 300, function() { $(this).hide(); });
            menu.slideUp(300, 'linear');
            toggleBut.removeClass('active');
        }
        else {
            menu.slideDown(300, 'linear');
            toggleBut.addClass('active');
        }
    });

    plusBut.on('click', function (e) {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).next('.subs').slideUp(300, 'linear');
        } else if (!$(this).next().is('.subs:animated')) {
            $(this).addClass('active');
            $(this).next('.subs').slideDown(300, 'linear');
        }
        // e.preventDefault();
    });

    $(window).on('resize', function() {
        setTimeout(function() {
            if (window.innerWidth >= 769) {
                menu.css('display', 'inline-block');
                menu.find('.active').each(function() {
                    $(this).removeClass('active');
                    $(this).next().css('display', 'block');
                })
            } else if (!toggleBut.hasClass('active')) menu.hide();
        }, 50);
    });

    $(document).on('click touchstart', function(event) {
		var $target = $(event.target);
        if (!$target.is(menu) && !menu.find($target).length && !$target.is(toggleBut) && !toggleBut.find($target).length && toggleBut.hasClass('active')) toggleBut.trigger('click');
    });
}

const totop = function () {
    totop.$but = $('.totop');
    totop.$window = $(window);
    totop.$scrollEl = $('#head');

    totop.duration = 400;

    totop.showBut();
    totop.windowEvents();
    totop.scrollToTop();
}

totop.scrollToTop = function () {
    totop.$but.click(function () {
        $('html,body').animate({'scrollTop' : 0}, totop.duration);
    });
}

totop.calcHeightDoc = function () {
	var scrollHeight = Math.max(
		document.body.scrollHeight, document.documentElement.scrollHeight,
		document.body.offsetHeight, document.documentElement.offsetHeight,
		document.body.clientHeight, document.documentElement.clientHeight
	);

	return scrollHeight;
}

totop.showBut = function () {
	var _heightWin = document.documentElement.clientHeight + totop.$scrollEl.height();
	var _top = totop.$scrollEl.offset().top + totop.$scrollEl.height();
	//console.log(totop.calcHeightDoc()+' '+_heightWin)
    if (totop.$window.scrollTop() >= _top && totop.calcHeightDoc() > _heightWin) totop.$but.addClass('vis');
    else totop.$but.removeClass('vis');
}

totop.windowEvents = function () {
    totop.$window.on('scroll', function () {
        setTimeout(function () {
            totop.showBut();
        }, 50);
    });

    totop.$window.on('resize', function () {
        setTimeout(function () {
            totop.showBut();
        }, 50);
    });
}

function tomap () {
    var but = $('.tomap');
    var map = $('#map');
    
    but.on('click', function(event) {
        scrollToo(map, 400, -30);
        event.preventDefault();
    });
}

function showAdress() {
    var links = $('.linkShow');
    var urlAjax = $('meta[name="contacts:email"]').attr('content');

    links.on('click', function() {
        $.ajax({
            method: 'post',
            url: urlAjax,
            data: {
                key: $(this).data('key')
            }
        }).success(function(e) {
            $('<a href="mailto:' + e + '">' + e + '</a>').insertAfter($(this)), $(this).remove()
        }.bind(this)).error(function() {
            alert('Ошибка, обновите страницу');
        })
    });
}

function carousel () {
	$('.owl-carousel-destinations').each(function() {
        $(this).owlCarousel({
            loop:true,
            margin:30,
            autoplay:true,
            autoplayTimeout:8000,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1,
                    nav:true
                },
                480:{
                    items:2,
                    nav:true
                },
                600:{
                    items:3,
                    nav:true
                },
                768:{
                    items:4,
                    nav:true
                }/*,
                1000:{
                    items:5,
                    nav:true
                }*/
            }
        });
    });

    if ($('.owl-carousel-kashback').find('.carousel__item').length > 3) {
        $('.owl-carousel-kashback').addClass('owl-carousel').owlCarousel({
            loop:true,
            margin:30,
            // autoplay:true,
            // autoplayTimeout:8000,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1,
                    nav:true
                },
                400:{
                    items:2,
                    nav:true
                },
                600:{
                    items:3,
                    nav:true
                },
                750:{
                    items:4,
                    nav:true
                },
                900:{
                    items:5,
                    nav:true
                }/*,
                1000:{
                    items:5,
                    nav:true
                }*/
            }
        });
    }
    
    var owl = $('.slider__box');
    owl.owlCarousel({
        items:1,
        loop:true,
        margin:0,
        autoplay:true,
        nav:true,
        autoplayTimeout:8000,
        autoplayHoverPause:true
    });

    var owl = $('.opinions__box');
    owl.owlCarousel({
        items:1,
        loop:true,
        margin:0,
        autoplay:true,
        nav:true,
        autoplayTimeout:8000,
        autoplayHoverPause:true
    });
}

const selector = function () {
	selector.$selector = $('.tourSearch .tourSearch__selector__field');
	selector.$options = $('.tourSearch .tourSearch__selector__options');
	selector.$hiddenInp = $('.tourSearch .tourSearch__selector').find('#geocode');

	selector.$selector.click(function() {
		selector.$options.css('display') != 'block' ? selector.$options.show() : selector.$options.hide();
	});

	selector.$options.find('div').click(function() {
		if (!$(this).hasClass('sel')) {
			selector.$options.find('.sel').removeClass('sel');
			$(this).addClass('sel');
			selector.$selector.text($(this).text());
			selector.$hiddenInp.val($(this).data('id'));
		}
		selector.$options.hide();
	});

	$(document).on('click', function(event) {
		var $target = $(event.target);
        if (!$target.is(selector.$selector)) selector.$options.hide();
    });
}

function datepickerExec () {
    var num = window.innerWidth < 700 ? 1 : 2;
	var today = new Date().getTime();
	$('.t-datepicker').tDatePicker({
		autoClose        : true,
		durationArrowTop : 200,
		numCalendar    : num,
		titleCheckIn   : 'Туда',
		titleCheckOut  : 'Обратно',
		titleToday     : 'Сегодня',
		titleDateRange : 'night',
		titleDateRanges: 'nights',
		titleDays      : [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс' ],
		titleMonths    : ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
		titleMonthsLimitShow : 3,
		replaceTitleMonths : null,
		showDateTheme   : null,
		iconArrowTop : true,
		iconDate     : '&#x279C;',
		arrowPrev    : '&#x276E;',
		arrowNext    : '&#x276F;',
		toDayShowTitle       : true,
		dateRangesShowTitle  : true,
		toDayHighlighted     : false,
		nextDayHighlighted   : false,
		daysOfWeekHighlighted: [0,6],
		formatDate      : 'dd-mm-yyyy',
		dateCheckIn  : null,
		dateCheckOut : null,
		startDate    : new Date(today + 24 * 60 * 60 * 1000),
		endDate      : new Date(today + (24 * 60 * 60 * 1000) * 365),
		limitPrevMonth : 0,
		limitNextMonth : 11,
		limitDateRanges    : 90,
		showFullDateRanges : false,
		fnDataEvent   : null
	});
}

function formSearch() {
	selector();
	datepickerExec();

	var forma = $('.tourSearch form');
	var button = $('.tourSearch .button');

	button.on('click', function() {
		forma.trigger('submit');
	});
}

const filters = function () {
    filters.forma = $('.filters').find('form');
    filters.moreBut = $('.filters').find('.filters__item__more a');
    filters.openFilterBut = $('.filters').find('.filters__item__title');
    filters.resetBut = $('.filters').find('.filters__reset');
    filters.cost = $('#cost');
    filters.inputCostMin = $('.filters').find('#costMin');
    filters.inputCostMax = $('.filters').find('#costMax');

    filters.costMin = $('meta[name="catalog:cost-min"]').attr('content');
    filters.costMax = $('meta[name="catalog:cost-max"]').attr('content');
    filters.costStep = $('meta[name="catalog:cost-step"]').attr('content');

    filters.requestNow = 0;
    filters.urlParamsLength = 0;
    filters.sliderChange = 0;

    filters.exec();
}

filters.showMore = function () {

	filters.moreBut.click(function(event) {
        if (!$(this).parent().prev().hasClass('vis')) {
            $(this).parent().prev().addClass('vis');
            $(this).text('Свернуть');
        } else {
            $(this).parent().prev().removeClass('vis');
            $(this).text('Показать больше');
        }
        event.preventDefault();
    });

    filters.forma.find('.filters__item__hidden').each(function() {
        if ($(this).find('input:checked').length) $(this).parents('.filters__item').find('.filters__item__more a').click();
    });

    filters.openFilterBut.click(function() {
        if (window.innerWidth < 769) {
            $(this).parent().hasClass('vis') ? $(this).parent().removeClass('vis') : $(this).parent().addClass('vis');
        }
	});
}

filters.resetSliderRange = function () {
    filters.inputCostMin.val(filters.costMin);
    filters.inputCostMax.val(filters.costMax);
}

filters.resetForm = function (urlParamsObject) {
    // if (filters.urlParamsLength == 2) {
    //     if (urlParamsObject['min'] != filters.costMin || urlParamsObject['max'] != filters.costMax) filters.resetBut.show();
    //     else window.location = window.location.href.split('?')[0];
    // } else filters.resetBut.show();

    var counter = 0;
    for (var key in urlParamsObject) {
        counter++;
    }
    if (counter > 2 || filters.sliderChange) filters.resetBut.show();

    filters.resetBut.click(function () {
        filters.resetSliderRange();
		window.location = window.location.href.split('?')[0];
    });
}

filters.submitForm = function () {
    filters.requestNow = 1;
    setTimeout(function () {
        filters.requestNow = 0;
        filters.forma.submit();
    }, 2000);
}

filters.checkboxEvents = function (urlParamsObject) {
    $.each(urlParamsObject, function(name, value) {
        if( typeof value !== 'object' ) {
            filters.forma.find('[name = "'+decodeURIComponent(name)+'"][value = "'+decodeURIComponent(value)+'"]').trigger('click');
        } else {
            $.each(filters.forma.find('[name = "'+decodeURIComponent(name)+'"]'), function() {
                if (value.indexOf($(this).val()) !== -1) $(this).trigger('click');
            });
        }
    });
    
    filters.forma.find('input:checkbox, input:radio').change(function () {
        if (!filters.requestNow) filters.submitForm();
    });
}

filters.sliderRange = function (start, end) {
    var start = start || filters.costMin;
    var end = end || filters.costMax;

    filters.cost.ionRangeSlider({
        type: 'double',
        min: filters.costMin,
        max: filters.costMax,
        from: start,
        to: end,
        step: filters.costStep,
        min_interval: filters.costStep,
        prettify_separator: ' ',
        onStart: function (data) {
            //console.log(data.min+' '+data.max);
        },
        onChange: function (data) {
            //my code
            filters.inputCostMin.val(filters.cost.data('from'));
            filters.inputCostMax.val(filters.cost.data('to'));
            filters.submitForm();
        },
        onFinish: function (data) {
            //console.log(data);
        },
        onUpdate: function (data) {
            //console.log(data);
        }
    });
}

filters.setSliderParams = function (urlParamsObject) {
	var start = urlParamsObject[filters.inputCostMin.attr('name')];
    var end = urlParamsObject[filters.inputCostMax.attr('name')];

    filters.inputCostMin.val(start);
    filters.inputCostMax.val(end);
    
    filters.sliderRange(start, end);

    if (start != filters.costMin || end != filters.costMax) filters.sliderChange = 1;
}

filters.getAllUrlParams = function () {
    var uri = decodeURI(location.search.substr(1));
    var vars = uri.split('&');
    var result = {};

    if (uri) {
        for (var i=0; i < vars.length; i++) {
            var r = vars[i].split('=');
            //result[r[0]] = r[1];
            if(r[0].search("\\[\\]") !== -1) {
                typeof result[r[0]] === 'undefined' ? result[r[0]] = [r[1]] : result[r[0]].push(r[1]);
            } else {
                result[r[0]] = r[1];
            }
        }
    }

    return result;
}

filters.exec = function () {
    //var countKey = 0;

    //filters.sliderRange(filters.rangeStart, filters.rangeEnd, filters.rangeMin, filters.rangeMax);

    var urlParamsObject = filters.getAllUrlParams();

	// $.each(urlParamsObject, function(name, value) {
    //     filters.forma.find('[name = "'+decodeURIComponent(name)+'"][value = "'+decodeURIComponent(value)+'"]').prop('checked', true);
    //     //countKey += 1;
	// });
	
    if (!$.isEmptyObject(urlParamsObject)) {
    //if (countKey > 1) {
        filters.checkboxEvents(urlParamsObject);
		filters.setSliderParams(urlParamsObject);
		filters.resetForm(urlParamsObject);
    } else {
        filters.sliderRange();
        filters.checkboxEvents();
    }
    
    filters.showMore();
}

function accordion () {
    var container = $('.accordionBlock');
    var link = $('.accordionBlock .accordionBlock__title a');

    link.on('click', function (event) {
        if ($(this).parent().hasClass('active') || $(this).parent().next().css('display') == 'block') {
            $(this).parent().removeClass('active').next('.accordionBlock__box').slideUp(300, 'linear');
        } else if (!$(this).parent().next().is('div:animated')) {
            container.find('.active').removeClass('active').next('.accordionBlock__box').slideUp(300, 'linear');
            $(this).parent().addClass('active').next('.accordionBlock__box').slideDown(300, 'linear');
        }
        event.preventDefault();
    });

    //link.eq(0).click();
}

function selectDate () {
    var links = $('.tour .selDate');
    var dateselector = $('#i5');
    var bookforma = $('.tobook')

    links.on('click', function(e) {
        var index = $(this).attr('href');
        dateselector.find('option:eq('+index+')').prop('selected',true);
        dateselector.prev().hide();
        e.preventDefault();

        if (window.innerWidth < 769) scrollToo(bookforma, 400, -30);
    });
}

function fixPanel () {
    var openBut = $('#head .contactsToggle');
    var panel = $('.fixPanel');
    var closeBut = panel.find('.icon-close-round');

    openBut.on('click', function() {
        panel.addClass('open');
    });

    closeBut.on('click', function() {
        panel.removeClass('open');
    });

    $(document).on('click touchstart', function(event) {
		var $target = $(event.target);
        if (!$target.is(panel) && !panel.find($target).length && !$target.is(openBut) && !openBut.find($target).length && panel.hasClass('open')) closeBut.trigger('click');
    });
}

function openVideo () {
    $('.popupVideo').magnificPopup({
		disableOn: 700,
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,

		fixedContentPos: false
	});
}

function sendMsg () {
    var popup = $('.fixPopupMsg');
    var msg = $('.forma__sendMsg');
    var closeBut = $('.fixPopupMsg__close');
    
    var timeout;
    if (msg.find('div').length) {
        var classMsg = msg.find('div').attr('class');
        var textMsg = msg.find('div').text();
        popup.prepend('<div class="'+classMsg+'">'+textMsg+'</div>');
        popup.addClass('vis');
        timeout = setTimeout(function() {
            popup.animate({opacity : 'hide'}, 300, function() {
                $(this).removeClass('vis');
            });
        }, 5000);
        //popup.prepend('div class="'+classMsg+'"></div>')
    }

    closeBut.on('click', function() {
        clearTimeout(timeout);
        popup.animate({opacity : 'hide'}, 300, function() {
            $(this).removeClass('vis');
        })
    });
}

/********************************
********** Form Valid ***********
********************************/
const CheckForms = function (form, button, options) {
    this.form = form;
    this.button = form.find(button);

    this.options = $.extend({}, CheckForms.Defaults, options);

    if (this.options.radio) this.radioChange();

    this.checkEmpty();
    this.submitForm();
    this.removeError();
}

CheckForms.Defaults = {
    errorClass: 'errorField',
    emptyClass: 'emptyField',
    radio: 0,
}

CheckForms.prototype.checkEmpty = function () {
    this.form.find('.requiredField').each(function () {
        if ($(this).val() == '' || $(this).val() == $(this).parent().find('label').text() || ($(this).is('[type = checkbox') && !$(this).is('input:checked'))) $(this).addClass('emptyField');
    });

    this.checkAllElements();
}

CheckForms.prototype.checkCorrect = function () {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    var patternDate = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    this.form.find('.requiredField').each(function () {
        var item = $(this);
        var itemValue = item.val();
        var mailChecked = item.data('validate') != 'email' ? true : patternEmail.test(itemValue);
        var dateChecked = item.data('validate') != 'date' ? true : patternDate.test(itemValue);

        /**
         * @TODO:
         * 1. Get field type
         * 2. Check field by correct method (checkInput, checkRadio, checkCheckbox, checkEmail, checkDate)
         * 
         * checkDate by data-validate="date" + data-validatePattern="yyyy.mm.dd". Example: yyyy.mm.dd || dd.mm.yyyy
         **/ 
        if (!itemValue || !mailChecked || !dateChecked || itemValue == item.parent().find('label').text() || (item.is('[type = checkbox') && !item.is('input:checked'))) {
            item.addClass('errorField');
        }
        //if (item.is('[type = checkbox') && item.is('input:checked')) item.addClass('errorField');
    });

    this.checkAllElements();
}

CheckForms.prototype.removeError = function () {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    var patternDate = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    //return patternEmail.test(value);

    this.form.on('keyup blur change', '.requiredField', function () {
        var item = $(this);
        var itemValue = item.val();
        var mailChecked = item.data('validate') != 'email' ? true : patternEmail.test(itemValue);
        var dateChecked = item.data('validate') != 'date' ? true : patternDate.test(itemValue);

        if (itemValue && itemValue != item.parent().find('label').text() && mailChecked && dateChecked) {
            item.removeClass('errorField emptyField');
        } else {
            itemValue == item.parent().find('label').text() ? item.addClass('emptyField') : item.addClass('errorField');
            if (!dateChecked) item.addClass('errorField');
        }

        if (item.is('[type = checkbox')) {
            item.is('input:checked') ? item.removeClass('errorField emptyField') : item.addClass('errorField');
        }

        var form = item.parents('form');
        var submitButton = form.find('.button_submit');
        if (!form.find('.emptyField').length && !form.find('.errorField').length) {
            submitButton.removeClass('button_disabled');
        } else {
            submitButton.addClass('button_disabled');
        }
    });
}

CheckForms.prototype.checkEmail = function (value) {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    return patternEmail.test(value);
}

CheckForms.prototype.checkAllElements = function () {
    if (!this.form.find('.emptyField').length && !this.form.find('.errorField').length) {
        this.button.removeClass('button_disabled');
    } else {
        this.button.addClass('button_disabled');
    }
}

CheckForms.prototype.submitForm = function () {
    this.button.click(function (event) {
        //this.checkEmpty();
        this.checkCorrect();
        event.preventDefault();
        if (!this.form.find('.emptyField').length && !this.form.find('.errorField').length) {
            this.form.submit();
        }
    }.bind(this));
}

function execContactsForm() {
    var feedForm = new CheckForms($('#feedForm'), '.button_submit');
}

function execOrderForm() {
    var orderForm = new CheckForms($('#orderForm'), '.button_submit');
}

function popupFeedForm() {
    $('.popupFeedForm').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#name',

		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		callbacks: {
			beforeOpen: function() {
				if($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#name';
				}
			}
		}
    });
}

/********************************
******** END Form Valid *********
********************************/

function initMap() {
    var mapCenter = $('meta[name="contacts:map-center"]').attr('content');

    ymaps.ready(function () {
        var myMap = new ymaps.Map('map', {
            center: mapCenter.split(','),
            zoom: 16,
            controls: ['fullscreenControl', 'zoomControl', 'geolocationControl']
        });
        myMap.behaviors.disable(['scrollZoom', 'rightMouseButtonMagnifier', 'multiTouch']);

    	for (var key in places) {

    		placesExec = new ymaps.Placemark(
    		    places[key],
    		    {
    		        balloonContentHeader: '<div class="mcapt">' + places[key][2] + '</div>',
    		        balloonContentBody: '<div class="mimage">' + places[key][3] + '</div>',
    		        balloonContentFooter: '<div class="mbm">' + places[key][4] + '</div>'

    		    },
    		    {
                    preset: 'islands#dotIcon', 
                    iconColor: '#348961'
    		    }
    		);
    		myMap.geoObjects.add(placesExec);
    	}
    });
}

function popupGal() {
    var options = {
        delegate: 'a',
        type: 'image',
        tLoading: 'Загружено #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            tCounter: '<span class="mfp-counter">%curr% из %total%</span>', // markup of counter
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.',
            titleSrc: function(item) {
                return item.el.attr('title') + '<small></small>';
            }
        }
    };
    $('.popup-gallery').magnificPopup(options);
}

x.exe['option-default'] = ['menuToggle()', 'placeholder()', 'fixPanel()'/*, 'openVideo()'*/];
x.exe['option-index'] = ['totop()', 'carousel()', 'popupGal()', 'formSearch()'];
x.exe['option-contacts'] = ['sendMsg()', 'initMap()', 'execContactsForm()', 'popupFeedForm()', 'tomap()', 'showAdress()'];
x.exe['option-content'] = ['popupGal()']
x.exe['option-tours'] = ['filters()']
x.exe['option-tours-item'] = ['sendMsg()', 'accordion()', 'execOrderForm()', 'selectDate()', 'popupGal()']